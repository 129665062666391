<template>
  <div class="data-by-period">
    <div class="data-by-period__radio">
      <RadioGamePeriod :is-title-visible="false" :section="'promedio por partido'" />
    </div>
    <div class="data-by-period__playing-as">
      <div class="data-by-period__playing-as__team">
        <div class="data-by-period__playing-as__team__logo">
          <img v-if="selectedGame.homeTeam.logo" :src="selectedGame.homeTeam.logo" alt="" />
        </div>
        <div class="data-by-period__playing-as__team__name">
          {{ isRadioMobile ? selectedGame.homeTeam.acronym : selectedGame.homeTeam.colloquial_name }}
        </div>
      </div>
      <div class="data-by-period__playing-as__radio">
        <RadioPlayingAs :is-mobile="isRadioMobile" :section="'promedio por partido'" />
      </div>
      <div class="data-by-period__playing-as__team">
        <div class="data-by-period__playing-as__team__name">
          {{ isRadioMobile ? selectedGame.awayTeam.acronym : selectedGame.awayTeam.colloquial_name }}
        </div>
        <div class="data-by-period__playing-as__team__logo">
          <img v-if="selectedGame.awayTeam.logo" :src="selectedGame.awayTeam.logo" alt="" />
        </div>
      </div>
    </div>
    <br />
    <StatisticsCornersGoals :type="type" />
    <LegendLastSeason :mobile="isRadioMobile" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DataByPeriod',
  components: {
    LegendLastSeason: () => import('@/components/Sections/GolTipster-v2/General/LegendLastSeason'),
    RadioPlayingAs: () => import('@/components/Sections/GolTipster-v2/General/RadioPlayingAs'),
    RadioGamePeriod: () => import('@/components/Sections/GolTipster-v2/General/RadioGamePeriod'),
    StatisticsCornersGoals: () => import('@/components/Sections/GolTipster-v2/General/StatisticsCornersGoals'),
  },
  props: {
    type: {
      type: String,
      default: 'corners', // 'corners' or 'goals'
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('betsGeneral', ['selectedGame']),
    isRadioMobile() {
      return this.displayWidth < 464;
    },
  },
};
</script>

<style scoped lang="scss">
.data-by-period {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__radio {
    width: 100%;

    @media screen and (max-width: 929px) {
      display: none;
    }
  }

  &__playing-as {
    margin-top: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-row-gap: 0.875rem;

    &__team {
      display: flex;
      align-items: center;
      //grid-area: team;

      &:last-child {
        justify-self: flex-end;
      }

      &__logo {
        height: 3.5rem;
        width: 3.5rem;
      }

      &__name {
        font-family: Roboto-Black, sans-serif;
        font-size: 1.625rem;
        font-weight: 800;
        color: #132839;
      }
    }

    &__radio {
      //grid-area: radio;
    }

    @media screen and (max-width: 980px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        'team team'
        'radio2 radio2';

      &__team {
        grid-area: team;
      }

      &__radio {
        grid-area: radio2;
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }
}
</style>
